import React from 'react'
import SVG from 'react-inlinesvg'
import { HeaderContainer, HeaderHelp } from '../shared/elements'
import { logoutPath } from '../../utils/paths'

export default function Header(props) {
  return (
    <header>
      <HeaderContainer>
        <div className="logo">
          <SVG src="/images/logo-dark.svg" width={180} />
        </div>
        <div className="control">
          <HeaderHelp />

          <a href={logoutPath()} className="control-menu control-menu-btn">
            <span>Logout</span>
          </a>
        </div>
      </HeaderContainer>
    </header>
  )
}
