import React from 'react'
import styled from 'styled-components'
import Row from './ListTableRow'
import { SortColumn, directTableStyles } from '../shared/elements'

const Container = styled.div`
  ${directTableStyles}

  input[type='checkbox'] {
    position: relative;
    top: 2px;
  }

  a,
  button.a {
    color: var(--text-primary-hover);
    font: inherit;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: var(--text-primary);
    }
  }

  .approve-action {
    margin-left: 6px;
  }
`

export default function ConversionsTable(props) {
  const {
    conversions,
    selectedConversions,
    toggleConversionSelect,
    toggleAllConversionSelect,
    updateConversionStatus,
    setPublisherSource,
    order,
    setOrder,
    sort,
    setSort,
  } = props

  const sortProps = {
    order,
    setOrder,
    sort,
    setSort,
  }

  return (
    <Container className={!conversions?.length && 'no-scroll'}>
      <table className="table table-borderless">
        <thead>
          <tr>
            <td className="spacer" />
            <td className="px-0">
              <input
                type="checkbox"
                checked={
                  selectedConversions.length &&
                  conversions?.length === selectedConversions.length
                }
                onChange={toggleAllConversionSelect}
              />
            </td>
            <th>Transaction ID</th>
            <th>Order ID</th>
            <SortColumn
              element="th"
              field="affiliate_id"
              label="Partner"
              defaultSort="asc"
              {...sortProps}
            />
            <SortColumn
              element="th"
              field="offer_id"
              label="Campaign"
              defaultSort="asc"
              {...sortProps}
            />
            <SortColumn
              field="datetime"
              label="Date / time"
              defaultSort="desc"
              {...sortProps}
            />
            <td>Time-to-action</td>
            <SortColumn
              field="status"
              label="Status"
              defaultSort="asc"
              {...sortProps}
            />
            <td>Status message</td>
            <SortColumn
              className="text-right"
              field="payout"
              label="Payout"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="revenue"
              label="Revenue"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="sale_amount"
              label="Sale amount"
              defaultSort="desc"
              {...sortProps}
            />
            <td className="text-left">Tracking link referral</td>
            <td className="spacer" />
          </tr>
        </thead>

        <tbody>
          {Object.values(conversions).map((conversion) => (
            <Row
              key={conversion.tune_event_id}
              {...{
                conversion,
                selectedConversions,
                setPublisherSource,
                updateConversionStatus,
                toggleConversionSelect,
              }}
            />
          ))}
        </tbody>
      </table>
    </Container>
  )
}
